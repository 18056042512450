// @flow
import * as React from 'react'
import HeaderService from 'components/HeaderService'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import usePromoCode from 'util/hooks/usePromoCode'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'
import { formatDateTime } from 'i18n/DateFormat'

import transDomain from './translations/index.translations'

const VoucherService = withTranslation(transDomain)(({ theme, t }) => {
    const { state } = React.useContext(Store)
    const { locale, currency } = state
    const currencyFormatter = Currency(currency, locale)
    const { incentives, expiryDate } = state.campaign
    const promoCode = usePromoCode()
    return (
        <div className={styles.voucher} style={{
            '--primary-color': theme.primaryColor,
            '--text-color': theme.textColor,
            '--disclaimer-color': theme.disclaimerColor,
            '--promo-code-color': theme.promoCodeColor,
            '--link-color': theme.linkColor,
            '--border-top-color': theme.borderTopColor,
            '--title-color': theme.titleColor,
            '--message-title-color': theme.messageTitleColor
        }}>

            <span className={styles.borderTop}>
                <strong>{t('top_bar.title')}&nbsp;</strong> {t('top_bar.description', {
                    expiryDate: formatDateTime(expiryDate, 'long')
                })}
            </span>

            <HeaderService />

            <div className={styles.body}>
                <div>
                    <h1>{t('title', {
                        value: currencyFormatter.format(incentives.rewards.value),
                        type: incentives.rewards.type
                    })}</h1>
                </div>
                <div className={styles.rightSide} style={{
                    backgroundImage: `url(${theme.maskBg})`
                }}>
                    <span>Grab these <strong>great brands</strong> and more on your personal shopping spree!</span>
                </div>
            </div>

            <div className={styles.separator}>
                <div className={styles.text1}>
                    {t('visit')}
                </div>
                <a
                    href={`${window.location.host}/${window.location.pathname.split('/')[1]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.block1}>
                    {window.location.host}/{window.location.pathname.split('/')[1]}
                </a>
                <div className={styles.text2}>
                    {t('promo_code')}
                </div>
                <div className={styles.block2}>{promoCode}</div>
            </div>

            <div className={styles.footer}>
                <div className={styles.disclaimer}>
                    {t('disclaimer')}
                </div>
                <div className={cn(styles.logoContainer, {
                    [styles.alignRightLogo]: theme.alignRightLogo
                })}>
                    <div style={{
                        '--logo': `url(${theme.manufacturerLogo})`
                    }} />
                </div>
            </div>

        </div>
    )
})

const PromoService = props => {
    return (
        <div className={styles.container}>
            <VoucherService {...props} />
            <br />
            <VoucherService {...props} />
            <br />
        </div>
    )
}

export default withTheme(themes)(PromoService)
