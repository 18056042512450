// @flow
import * as React from 'react'

import Checkout from 'components/Checkout'

type Props = {
    location: String,
}

export default (props: Props) => {
    return (
        <Checkout location={props.location} />
    )
}
