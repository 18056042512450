// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { Currency } from 'i18n/NumberFormatter'
import PopupMessage from 'components/PopupMessage'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Store } from 'Store.js'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import * as Form from 'components/Form'
import transDomain from './translations/index.translations'
import cn from 'classnames'

function ShoppingItem ({
    theme,
    image,
    src,
    t,
    height,
    width,
    onClick,
    xl,
    options
}) {
    const { state } = React.useContext(Store)
    const [selectedSku, setSku] = React.useState(null)
    const [showError, setShowError] = React.useState(null)
    const currencyFormatter = Currency(state.currency, state.locale)
    const [
        currentValues,
        valuesDictionary,
        formatValuesDictionary
    ] = React.useMemo(() => {
        const formatValuesDictionary = {}
        const valuesDictionary = {}
        const currentValues = options.map(v => {
            formatValuesDictionary[`${v.sku}-${v.value}`] = currencyFormatter.format(v.value)
            valuesDictionary[`${v.sku}-${v.value}`] = v
            return formatValuesDictionary[`${v.sku}-${v.value}`]
        })
        return [currentValues, valuesDictionary, formatValuesDictionary]
    }, [ currencyFormatter, options ])

    const handleOnClick = React.useCallback(() => {
        if (!selectedSku) return setShowError(true)
        onClick(valuesDictionary[selectedSku].sku, valuesDictionary[selectedSku].value, image, src)
        setSku(null)
    }, [image, onClick, selectedSku, src, valuesDictionary])

    const handleOnChange = React.useCallback(value => {
        const current = _.keys(formatValuesDictionary).find(key => formatValuesDictionary[key] === value)
        setSku(current)
        setShowError(false)
    }, [formatValuesDictionary])

    React.useEffect(() => {
        if (showError) {
            setTimeout(() => setShowError(null), 2500)
        }
    }, [ showError ])

    return (
        <div
            className={cn(styles.container, {
                [styles.withLabel]: true
            })}
            style={{
                backgroundColor: theme.background,
                '--color-selected': theme.colorSelected
            }}>
            <div className={styles.description}>{src}</div>
            <div className={cn(styles.imageContainer, { [styles.xl]: xl })}>
                <img src={image} alt={src} width={width} height={height} />
            </div>
            <Form.Field label={''}>
                <Form.Dropdown
                    placeholder={t('placeholder')}
                    options={currentValues}
                    trans={t}
                    defaultValue={formatValuesDictionary[selectedSku]}
                    onChange={handleOnChange}
                    required
                />
            </Form.Field>
            <span onClick={handleOnClick} className={styles.button} style={{
                '--border-color': theme.borderColor,
                '--text-color': theme.textColor
            }}>
                  Add To Cart
            </span>
            {showError && <PopupMessage containerStyle={{
                left: '0.5em',
                right: '0.5em',
                bottom: '2em'
            }} message={'Please select an amount'} />}
        </div>
    )
}

function areEqual (prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps)
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(React.memo(ShoppingItem, areEqual)))
