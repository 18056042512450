// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import OrderItem from 'components/OrderItem'
import { Scrollbars } from 'react-custom-scrollbars'
import Icon from './Icon'
import CloseIcon from './CloseIcon'

import cn from 'classnames'

export const HeadingMenu = withTranslation(transDomain)(withTheme(supportedThemes)(({
    confirm,
    theme,
    items = 3,
    t,
    className,
    value,
    onClickClose
}) => {
    return (
        <div className={cn(styles.headingMenu, className)}>
            <CloseIcon onClick={onClickClose} />
            <h3 style={{ '--title-color': theme.titleColor }}>Your Cart</h3>
            <div className={styles.textAreaMenu} style={{ '--value-color': theme.valueColor }}>
                <strong>{value}</strong>  <span>Remaining</span>
            </div>
        </div>
    )
}))

export const Heading = withTranslation(transDomain)(withTheme(supportedThemes)(({
    confirm,
    theme,
    items = 0,
    t,
    className,
    onClickIcon,
    value,
    handleConfirm
}) => {
    return (
        <div className={cn(styles.heading, className)}
            style={{
                '--bg-desktop-color': theme.headingBg,
                '--bg-tablet-color': theme.headingBgTablet
            }}>
            <div className={styles.textArea}>
                <strong>{value}</strong>  <span>Remaining</span>
            </div>
            <div className={styles.confirmBtn}>
                <Confirm text='Confirm Rewards' onClick={handleConfirm} />
            </div>
            <div className={styles.iconContainer} onClick={onClickIcon}>
                <Icon />
                {items > 0 && (
                    <span className={styles.bubble} style={{
                        '--border-color': theme.borderColor,
                        '--background-color': theme.bubbleBg
                    }}>
                        {items}
                    </span>
                )}
            </div>
        </div>
    )
}))

export const Confirm = withTranslation(transDomain)(withTheme(supportedThemes)(({ text, theme, t, className, onClick, disabled }) => {
    return (
        <div className={cn(styles.confirm, className, {
            [styles.disabled]: disabled
        })} onClick={onClick} style={{
            '--bg-color': theme.btnColor,
            '--bg-tablet-color': theme.btnTabletColor
        }}>
            {text}
        </div>
    )
}))

export const OrderSummaryLayout = withTranslation(transDomain)(withTheme(supportedThemes)(({
    theme,
    t,
    heading,
    footer,
    children,
    className,
    bodyClassName,
    footerClassName,
    setRef
}) => {
    return (
        <div
            className={cn(styles.container, className)}
            style={{
                backgroundColor: theme.orderSummaryBg
            }}>
            {heading}
            <div className={cn(styles.body, bodyClassName)}>
                <Scrollbars
                    autoHide
                    autoHideTimeout={2000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMax={'75vh'}
                    ref={setRef ? s => { setRef(s) } : setRef}
                    thumbMinSize={30}>
                    {children}
                </Scrollbars>
            </div>
            <div className={cn(styles.footer, footerClassName)}>
                {footer}
            </div>
        </div>
    )
}))

function OrderSummary ({ theme, t, heading, footer, className }) {
    // const { state } = React.useContext(Store)
    return (
        <OrderSummaryLayout heading={heading} footer={footer} className={className}>
            <OrderItem />
            <OrderItem />
            <OrderItem />
        </OrderSummaryLayout>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(OrderSummary))
