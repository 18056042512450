import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

function EventLogo (props) {
    const { width, height, eventColor, secondaryColor, borderColor } = props
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...props}>
            <defs>
                <linearGradient
                    x1="66.502%"
                    y1="61.5%"
                    x2="31.16%"
                    y2="33.917%"
                    id="prefix__a"
          >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#F2F2F2" offset="99.91%" />
                </linearGradient>
                <linearGradient
                    x1="39.484%"
                    y1="30.953%"
                    x2="68.221%"
                    y2="53.396%"
                    id="prefix__b"
          >
                    <stop stopColor="#E8E8E8" offset="0%" />
                    <stop stopColor="#E1E1E1" offset="11.74%" />
                    <stop stopColor="#D4D4D5" offset="28.52%" />
                    <stop stopColor="#C0BFC2" offset="48.32%" />
                    <stop stopColor="#A1A1A4" offset="70.44%" />
                    <stop stopColor="#7C7B80" offset="94.2%" />
                    <stop stopColor="#737176" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="45.75%"
                    y1="32%"
                    x2="64.378%"
                    y2="55.68%"
                    id="prefix__c"
          >
                    <stop stopColor="#E8E8E8" offset="0%" />
                    <stop stopColor="#EEE" offset="12.54%" />
                    <stop stopColor="#FFF" offset="25.82%" />
                    <stop stopColor="#F5F5F4" offset="40.64%" />
                    <stop stopColor="#E8E8E8" offset="56.04%" />
                    <stop stopColor="#D1D2D2" offset="73.17%" />
                    <stop stopColor="#B8B9BA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1=".687%"
                    y1="48.942%"
                    x2="96.506%"
                    y2="50.707%"
                    id="prefix__d"
          >
                    <stop stopColor="#FDFDFD" offset="0%" />
                    <stop stopColor="#FAFAFB" offset="50.26%" />
                    <stop stopColor="#FDFDFD" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1=".979%"
                    y1="47.813%"
                    x2="96.058%"
                    y2="51.41%"
                    id="prefix__e"
          >
                    <stop stopColor="#FCFCFC" offset="0%" />
                    <stop stopColor="#F7F7F7" offset="50.26%" />
                    <stop stopColor="#FCFCFC" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="1.297%"
                    y1="46.687%"
                    x2="95.576%"
                    y2="52.171%"
                    id="prefix__f"
          >
                    <stop stopColor="#FAFAFB" offset="0%" />
                    <stop stopColor="#F4F4F5" offset="50.26%" />
                    <stop stopColor="#FAFAFB" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="1.678%"
                    y1="45.485%"
                    x2="95.08%"
                    y2="52.915%"
                    id="prefix__g"
          >
                    <stop stopColor="#F9F9F9" offset="0%" />
                    <stop stopColor="#F1F2F2" offset="50.26%" />
                    <stop stopColor="#F9F9F9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="2.082%"
                    y1="44.212%"
                    x2="94.537%"
                    y2="53.656%"
                    id="prefix__h"
          >
                    <stop stopColor="#F8F8F8" offset="0%" />
                    <stop stopColor="#EFEFF0" offset="50.26%" />
                    <stop stopColor="#F8F8F8" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="2.577%"
                    y1="42.912%"
                    x2="93.988%"
                    y2="54.434%"
                    id="prefix__i"
          >
                    <stop stopColor="#F7F7F7" offset="0%" />
                    <stop stopColor="#ECEDED" offset="50.26%" />
                    <stop stopColor="#F7F7F7" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="3.085%"
                    y1="41.625%"
                    x2="93.324%"
                    y2="55.272%"
                    id="prefix__j"
          >
                    <stop stopColor="#F6F6F7" offset="0%" />
                    <stop stopColor="#EAEAEB" offset="50.26%" />
                    <stop stopColor="#F6F6F7" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="3.737%"
                    y1="40.21%"
                    x2="92.702%"
                    y2="56.036%"
                    id="prefix__k"
          >
                    <stop stopColor="#F5F5F6" offset="0%" />
                    <stop stopColor="#E7E8E9" offset="50.26%" />
                    <stop stopColor="#F5F5F6" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="4.471%"
                    y1="38.775%"
                    x2="92.035%"
                    y2="56.832%"
                    id="prefix__l"
          >
                    <stop stopColor="#F4F4F5" offset="0%" />
                    <stop stopColor="#E4E5E6" offset="50.26%" />
                    <stop stopColor="#F4F4F5" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="5.276%"
                    y1="37.328%"
                    x2="91.345%"
                    y2="57.682%"
                    id="prefix__m"
          >
                    <stop stopColor="#F3F3F4" offset="0%" />
                    <stop stopColor="#E2E3E4" offset="50.26%" />
                    <stop stopColor="#F3F3F4" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="6.209%"
                    y1="35.806%"
                    x2="90.63%"
                    y2="58.471%"
                    id="prefix__n"
          >
                    <stop stopColor="#F2F3F3" offset="0%" />
                    <stop stopColor="#DFE0E1" offset="50.26%" />
                    <stop stopColor="#F2F3F3" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="7.201%"
                    y1="34.296%"
                    x2="89.865%"
                    y2="59.315%"
                    id="prefix__o"
          >
                    <stop stopColor="#F1F2F2" offset="0%" />
                    <stop stopColor="#DCDEDF" offset="50.26%" />
                    <stop stopColor="#F1F2F2" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="8.329%"
                    y1="32.719%"
                    x2="89.124%"
                    y2="60.128%"
                    id="prefix__p"
          >
                    <stop stopColor="#F0F1F1" offset="0%" />
                    <stop stopColor="#DADBDC" offset="50.26%" />
                    <stop stopColor="#F0F1F1" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="9.505%"
                    y1="31.164%"
                    x2="88.274%"
                    y2="60.982%"
                    id="prefix__q"
          >
                    <stop stopColor="#F0F0F1" offset="0%" />
                    <stop stopColor="#D7D9DA" offset="50.26%" />
                    <stop stopColor="#F0F0F1" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="10.866%"
                    y1="29.57%"
                    x2="87.462%"
                    y2="61.792%"
                    id="prefix__r"
          >
                    <stop stopColor="#EFEFF0" offset="0%" />
                    <stop stopColor="#D5D6D8" offset="50.26%" />
                    <stop stopColor="#EFEFF0" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="12.295%"
                    y1="27.998%"
                    x2="86.567%"
                    y2="62.617%"
                    id="prefix__s"
          >
                    <stop stopColor="#EEEEEF" offset="0%" />
                    <stop stopColor="#D2D3D5" offset="50.26%" />
                    <stop stopColor="#EEEEEF" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="13.939%"
                    y1="26.444%"
                    x2="85.715%"
                    y2="63.42%"
                    id="prefix__t"
          >
                    <stop stopColor="#EDEEEE" offset="0%" />
                    <stop stopColor="#CFD1D3" offset="50.26%" />
                    <stop stopColor="#EDEEEE" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="15.653%"
                    y1="24.971%"
                    x2="84.745%"
                    y2="64.25%"
                    id="prefix__u"
          >
                    <stop stopColor="#ECEDED" offset="0%" />
                    <stop stopColor="#CDCED0" offset="50.26%" />
                    <stop stopColor="#ECEDED" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="17.201%"
                    y1="23.882%"
                    x2="83.427%"
                    y2="65.368%"
                    id="prefix__v"
          >
                    <stop stopColor="#EBECED" offset="0%" />
                    <stop stopColor="#CACCCE" offset="50.26%" />
                    <stop stopColor="#EBECED" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="18.877%"
                    y1="23.072%"
                    x2="82.07%"
                    y2="66.64%"
                    id="prefix__w"
          >
                    <stop stopColor="#EAEBEC" offset="0%" />
                    <stop stopColor="#C8C9CB" offset="50.26%" />
                    <stop stopColor="#EAEBEC" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="20.551%"
                    y1="22.386%"
                    x2="80.56%"
                    y2="67.932%"
                    id="prefix__x"
          >
                    <stop stopColor="#EAEAEB" offset="0%" />
                    <stop stopColor="#C5C7C9" offset="50.26%" />
                    <stop stopColor="#EAEAEB" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="22.378%"
                    y1="21.694%"
                    x2="79.062%"
                    y2="69.034%"
                    id="prefix__y"
          >
                    <stop stopColor="#E9E9EA" offset="0%" />
                    <stop stopColor="#C2C4C6" offset="50.26%" />
                    <stop stopColor="#E9E9EA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="24.158%"
                    y1="21.148%"
                    x2="77.376%"
                    y2="70.091%"
                    id="prefix__z"
          >
                    <stop stopColor="#E8E8E9" offset="0%" />
                    <stop stopColor="#C0C2C4" offset="50.26%" />
                    <stop stopColor="#E8E8E9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="26.11%"
                    y1="20.709%"
                    x2="75.765%"
                    y2="71.027%"
                    id="prefix__A"
          >
                    <stop stopColor="#E7E8E9" offset="0%" />
                    <stop stopColor="#BDBFC1" offset="50.26%" />
                    <stop stopColor="#E7E8E9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="27.949%"
                    y1="20.52%"
                    x2="73.971%"
                    y2="72.014%"
                    id="prefix__B"
          >
                    <stop stopColor="#E6E7E8" offset="0%" />
                    <stop stopColor="#BBBDBF" offset="50.26%" />
                    <stop stopColor="#E6E7E8" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="29.936%"
                    y1="20.352%"
                    x2="72.306%"
                    y2="72.768%"
                    id="prefix__C"
          >
                    <stop stopColor="#E5E6E7" offset="0%" />
                    <stop stopColor="#B8BABC" offset="50.26%" />
                    <stop stopColor="#E5E6E7" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="31.754%"
                    y1="20.316%"
                    x2="70.464%"
                    y2="73.416%"
                    id="prefix__D"
          >
                    <stop stopColor="#E4E5E6" offset="0%" />
                    <stop stopColor="#B5B7BA" offset="50.26%" />
                    <stop stopColor="#E4E5E6" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="33.533%"
                    y1="20.374%"
                    x2="68.625%"
                    y2="73.927%"
                    id="prefix__E"
          >
                    <stop stopColor="#E3E4E5" offset="0%" />
                    <stop stopColor="#B3B5B7" offset="50.26%" />
                    <stop stopColor="#E3E4E5" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="35.427%"
                    y1="20.748%"
                    x2="66.992%"
                    y2="74.547%"
                    id="prefix__F"
          >
                    <stop stopColor="#E2E3E4" offset="0%" />
                    <stop stopColor="#B0B2B5" offset="50.26%" />
                    <stop stopColor="#E2E3E4" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="37.08%"
                    y1="21.065%"
                    x2="65.213%"
                    y2="74.894%"
                    id="prefix__G"
          >
                    <stop stopColor="#E2E3E4" offset="0%" />
                    <stop stopColor="#AEB0B2" offset="50.26%" />
                    <stop stopColor="#E2E3E4" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="38.833%"
                    y1="21.508%"
                    x2="63.681%"
                    y2="75.18%"
                    id="prefix__H"
          >
                    <stop stopColor="#E1E2E3" offset="0%" />
                    <stop stopColor="#ABADB0" offset="50.26%" />
                    <stop stopColor="#E1E2E3" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="40.327%"
                    y1="22.06%"
                    x2="62.041%"
                    y2="75.452%"
                    id="prefix__I"
          >
                    <stop stopColor="#E0E1E2" offset="0%" />
                    <stop stopColor="#A9ABAD" offset="50.26%" />
                    <stop stopColor="#E0E1E2" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="41.875%"
                    y1="22.819%"
                    x2="60.636%"
                    y2="75.83%"
                    id="prefix__J"
          >
                    <stop stopColor="#DFE0E1" offset="0%" />
                    <stop stopColor="#A6A8AB" offset="50.26%" />
                    <stop stopColor="#DFE0E1" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="43.066%"
                    y1="23.191%"
                    x2="59.12%"
                    y2="75.967%"
                    id="prefix__K"
          >
                    <stop stopColor="#DEDFE0" offset="0%" />
                    <stop stopColor="#A4A6A8" offset="50.26%" />
                    <stop stopColor="#DEDFE0" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="44.264%"
                    y1="23.322%"
                    x2="57.871%"
                    y2="76.181%"
                    id="prefix__L"
          >
                    <stop stopColor="#DDDEE0" offset="0%" />
                    <stop stopColor="#A1A3A6" offset="50.26%" />
                    <stop stopColor="#DDDEE0" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="45.182%"
                    y1="23.448%"
                    x2="56.475%"
                    y2="76.407%"
                    id="prefix__M"
          >
                    <stop stopColor="#DCDEDF" offset="0%" />
                    <stop stopColor="#9FA1A4" offset="50.26%" />
                    <stop stopColor="#DCDEDF" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="46.055%"
                    y1="23.657%"
                    x2="55.183%"
                    y2="76.956%"
                    id="prefix__N"
          >
                    <stop stopColor="#DCDDDE" offset="0%" />
                    <stop stopColor="#9C9FA1" offset="50.26%" />
                    <stop stopColor="#DCDDDE" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="47.05%"
                    y1="23.618%"
                    x2="54.185%"
                    y2="77.797%"
                    id="prefix__O"
          >
                    <stop stopColor="#DBDCDD" offset="0%" />
                    <stop stopColor="#9A9C9F" offset="50.26%" />
                    <stop stopColor="#DBDCDD" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="47.811%"
                    y1="23.536%"
                    x2="52.991%"
                    y2="78.649%"
                    id="prefix__P"
          >
                    <stop stopColor="#DADBDC" offset="0%" />
                    <stop stopColor="#989A9C" offset="50.26%" />
                    <stop stopColor="#DADBDC" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="48.644%"
                    y1="22.774%"
                    x2="51.952%"
                    y2="79.502%"
                    id="prefix__Q"
          >
                    <stop stopColor="#D9DADC" offset="0%" />
                    <stop stopColor="#95979A" offset="50.26%" />
                    <stop stopColor="#D9DADC" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="49.337%"
                    y1="21.833%"
                    x2="50.751%"
                    y2="80.169%"
                    id="prefix__R"
          >
                    <stop stopColor="#D8D9DB" offset="0%" />
                    <stop stopColor="#939598" offset="50.26%" />
                    <stop stopColor="#D8D9DB" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="50.173%"
                    y1="20.914%"
                    x2="49.685%"
                    y2="80.761%"
                    id="prefix__S"
          >
                    <stop stopColor="#D7D9DA" offset="0%" />
                    <stop stopColor="#909395" offset="50.26%" />
                    <stop stopColor="#D7D9DA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="51.02%"
                    y1="19.995%"
                    x2="48.628%"
                    y2="81.187%"
                    id="prefix__T"
          >
                    <stop stopColor="#D6D8D9" offset="0%" />
                    <stop stopColor="#8E9093" offset="50.26%" />
                    <stop stopColor="#D6D8D9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="51.924%"
                    y1="19.349%"
                    x2="47.643%"
                    y2="81.831%"
                    id="prefix__U"
          >
                    <stop stopColor="#D5D7D8" offset="0%" />
                    <stop stopColor="#8C8E90" offset="50.26%" />
                    <stop stopColor="#D5D7D8" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="52.654%"
                    y1="18.61%"
                    x2="46.494%"
                    y2="82.267%"
                    id="prefix__V"
          >
                    <stop stopColor="#D5D6D8" offset="0%" />
                    <stop stopColor="#898B8E" offset="50.26%" />
                    <stop stopColor="#D5D6D8" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="53.379%"
                    y1="17.903%"
                    x2="45.368%"
                    y2="82.588%"
                    id="prefix__W"
          >
                    <stop stopColor="#D4D5D7" offset="0%" />
                    <stop stopColor="#87898C" offset="50.26%" />
                    <stop stopColor="#D4D5D7" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="54.276%"
                    y1="17.166%"
                    x2="44.456%"
                    y2="82.805%"
                    id="prefix__X"
          >
                    <stop stopColor="#D3D4D6" offset="0%" />
                    <stop stopColor="#858789" offset="50.26%" />
                    <stop stopColor="#D3D4D6" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="54.995%"
                    y1="16.689%"
                    x2="43.393%"
                    y2="83.199%"
                    id="prefix__Y"
          >
                    <stop stopColor="#D2D3D5" offset="0%" />
                    <stop stopColor="#838487" offset="50.26%" />
                    <stop stopColor="#D2D3D5" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="55.846%"
                    y1="16.144%"
                    x2="42.515%"
                    y2="83.421%"
                    id="prefix__Z"
          >
                    <stop stopColor="#D1D3D4" offset="0%" />
                    <stop stopColor="#808285" offset="50.26%" />
                    <stop stopColor="#D1D3D4" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="39.824%"
                    y1="12.89%"
                    x2="53.4%"
                    y2="92.833%"
                    id="prefix__aa"
          >
                    <stop stopColor="#BCBEC0" offset="0%" />
                    <stop stopColor="#DADBDC" offset="6.59%" />
                    <stop stopColor="#FFF" offset="32.31%" />
                    <stop stopColor="#FFF" offset="69.74%" />
                    <stop stopColor="#DEDFE1" offset="89.01%" />
                    <stop stopColor="#A5A7AA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="51.259%"
                    y1="-.378%"
                    x2="49.28%"
                    y2="100.207%"
                    id="prefix__ab"
          >
                    <stop stopColor="#9B9DA0" offset="0%" />
                    <stop stopColor="#DEDFE1" offset="68.13%" />
                    <stop stopColor="#A5A7AA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="44.478%"
                    y1="9.967%"
                    x2="52.832%"
                    y2="95.969%"
                    id="prefix__ac"
          >
                    <stop stopColor="#BCBEC0" offset="0%" />
                    <stop stopColor="#DADBDC" offset="6.59%" />
                    <stop stopColor="#FFF" offset="32.31%" />
                    <stop stopColor="#FFF" offset="69.74%" />
                    <stop stopColor="#DEDFE1" offset="89.01%" />
                    <stop stopColor="#A5A7AA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="51.774%"
                    y1="-1.064%"
                    x2="48.234%"
                    y2="100.826%"
                    id="prefix__ad"
          >
                    <stop stopColor="#97999C" offset="0%" />
                    <stop stopColor="#DADBDC" offset="13.19%" />
                    <stop stopColor="#FFF" offset="46.15%" />
                    <stop stopColor="#DEDFE1" offset="78.02%" />
                    <stop stopColor="#95979A" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="41.085%"
                    y1="11.169%"
                    x2="57.257%"
                    y2="83.524%"
                    id="prefix__ae"
          >
                    <stop stopColor="#BCBEC0" offset="0%" />
                    <stop stopColor="#DADBDC" offset="6.59%" />
                    <stop stopColor="#FFF" offset="32.31%" />
                    <stop stopColor="#FFF" offset="69.74%" />
                    <stop stopColor="#DEDFE1" offset="89.01%" />
                    <stop stopColor="#A5A7AA" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="53.462%"
                    y1="47.676%"
                    x2="46.409%"
                    y2="53.842%"
                    id="prefix__af"
          >
                    <stop stopColor="#A1A3A6" offset="0%" />
                    <stop stopColor="#C9CACC" offset="20.88%" />
                    <stop stopColor="#FFF" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="57.154%"
                    y1="43.127%"
                    x2="42.356%"
                    y2="57.626%"
                    id="prefix__ag"
          >
                    <stop stopColor="#A1A3A6" offset="0%" />
                    <stop stopColor="#C9CACC" offset="20.88%" />
                    <stop stopColor="#FFF" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="53.25%"
                    y1="41.133%"
                    x2="46.812%"
                    y2="58.896%"
                    id="prefix__ah"
          >
                    <stop stopColor="#E4E5E6" offset="0%" />
                    <stop stopColor="#FFF" offset="37.36%" />
                    <stop stopColor="#E1E2E3" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="42.747%"
                    y1="11.722%"
                    x2="56.443%"
                    y2="63.608%"
                    id="prefix__ai"
          >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#BCBEC0" offset="10.99%" />
                    <stop stopColor="#F3F4F4" offset="48.35%" />
                    <stop stopColor="#E1E2E3" offset="90.66%" />
                    <stop stopColor="#B1B3B6" offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M83.498 9.9c-4.658-1.366-10.126-1.794-16.27-1.236l-29.186 2.653c-4.533.412-8.39 4.103-9.094 8.704l-6.64 43.242-1.579 9.52c-.754 4.55-1.36 9.009-1.946 13.321l-.172 1.216-.985.412-.93.388c-2.306.954-5.855 2.42-8.07 5.947-.505.4-.943.82-1.318 1.244a11.108 11.108 0 00-3.286 3.346c-1.575 2.463-2.627 6.447-3.127 11.848-.16 1.723-.207 3.251-.149 4.534.063 1.338.254 5.41 3.604 8.07a8.587 8.587 0 002.063 1.2 9.238 9.238 0 002.576 2.384 9.095 9.095 0 004.56 1.35c.384.012.74 0 1.072-.027 1.274 1.005 2.837 1.655 4.576 1.841.641.067 1.286.071 1.935.012.992-.091 2-.329 3.056-.717 2.849-1.053 8.062-3.46 14.14-6.276 2.9-1.343 5.893-2.729 8.605-3.948a28.497 28.497 0 01.696-.297c.465-.198.926-.392 1.38-.602.082.079.156.166.242.241l-2.45 12.783c-2.76 1.465-4.847 4.193-5.405 7.405l-.657 3.774c-.48 2.764.254 5.488 2.005 7.448 1.75 1.96 4.334 2.946 7.062 2.7l36.842-3.35c-.105 2.365.673 4.614 2.197 6.285 1.75 1.917 4.307 2.879 7 2.634l28.252-2.566c7.922-.721 15.312-3.228 21.737-7.188a8.311 8.311 0 001.262 1.83c1.751 1.916 4.307 2.879 7 2.633l63.477-5.77c3.783-.344 7.183-2.997 8.567-6.692l.715-1.908 2.321-.21c.75 4.383 4.565 7.341 9.075 6.93l44.155-3.94 30.87-34.365a7.873 7.873 0 00-1.93-3.698c-1.751-1.913-4.307-2.875-7-2.63l-7.945.721 4.248-27.596 2.247-.206c4.534-.412 8.395-4.107 9.099-8.708l2.653-17.36c.418-2.733-.348-5.405-2.098-7.322-1.751-1.913-4.307-2.875-7-2.63l-5.276.48 3.978-25.795c.423-2.732-.343-5.413-2.094-7.326-1.751-1.917-4.307-2.879-7-2.633l-19.13 1.738c-3.623.329-6.812 2.752-8.31 6.059-1.5-3.045-4.697-4.879-8.308-4.55L238.255 7.17c-3.474.317-6.652 2.586-8.219 5.869l-.336.705a8.276 8.276 0 00-1.528-2.396c-1.751-1.909-4.303-2.87-6.992-2.625L97.005 19.34s-1.56-4.538-13.507-9.44z"
                    fill={borderColor}
                    fillRule="nonzero"
          />
                <path
                    d="M83.108 8.316C78.449 6.95 72.982 6.522 66.838 7.08L37.65 9.733c-4.533.412-8.39 4.103-9.094 8.704l-6.64 43.242-1.579 9.52c-.754 4.55-1.36 9.009-1.946 13.321l-.172 1.216-.985.412-.93.388c-2.306.954-5.855 2.42-8.07 5.947-.505.4-.942.82-1.318 1.244a11.108 11.108 0 00-3.286 3.346c-1.575 2.463-2.627 6.447-3.127 11.848-.16 1.723-.207 3.251-.148 4.534.062 1.339.254 5.41 3.603 8.07a8.587 8.587 0 002.064 1.2 9.238 9.238 0 002.575 2.384 9.095 9.095 0 004.56 1.35c.384.012.74 0 1.072-.027 1.274 1.005 2.837 1.655 4.576 1.841.641.067 1.286.071 1.935.012.992-.091 2-.329 3.056-.717 2.849-1.053 8.063-3.46 14.14-6.276 2.9-1.343 5.893-2.729 8.606-3.948a28.497 28.497 0 01.696-.297c.464-.198.926-.392 1.379-.602.082.079.156.166.242.241l-2.45 12.783c-2.76 1.465-4.846 4.193-5.405 7.405l-.657 3.774c-.48 2.764.254 5.488 2.005 7.448 1.75 1.96 4.334 2.946 7.062 2.7l36.843-3.35c-.106 2.365.672 4.614 2.196 6.285 1.75 1.917 4.307 2.88 7 2.634l28.252-2.566c7.922-.721 15.312-3.228 21.737-7.188a8.311 8.311 0 001.263 1.83c1.75 1.916 4.306 2.879 7 2.633l63.476-5.77c3.783-.344 7.183-2.997 8.567-6.692l.715-1.908 2.321-.21c.75 4.383 4.565 7.341 9.075 6.93l44.155-3.94 30.87-34.365a7.873 7.873 0 00-1.93-3.698c-1.75-1.913-4.307-2.875-7-2.63l-7.945.721 4.248-27.596 2.248-.206c4.533-.412 8.394-4.107 9.098-8.708l2.653-17.36c.419-2.733-.347-5.405-2.098-7.322-1.751-1.913-4.307-2.875-7-2.63l-5.276.48 3.979-25.795c.422-2.732-.344-5.413-2.095-7.326-1.751-1.916-4.307-2.879-7-2.633l-19.13 1.738c-3.623.329-6.812 2.752-8.31 6.059-1.5-3.045-4.697-4.879-8.308-4.55l-21.155 1.924c-3.474.317-6.652 2.586-8.219 5.869l-.336.705a8.276 8.276 0 00-1.528-2.396c-1.75-1.909-4.303-2.87-6.992-2.625L96.614 17.756s-1.559-4.538-13.506-9.44z"
                    fill="url(#prefix__a)"
                    fillRule="nonzero"
          />
                <g fill={eventColor}>
                    <path d="M55.39 56.698c-1.758 10.68-3.552 21.348-4.888 31.98-.9 7.148-2.142 15.325-4.206 18.548-.387.606-.79.634-1.41.863-6.418 2.4-22.906 9.686-24.177 10.213 5.32-15.93 6.097-30.65 8.7-46.359l2.17-13.08 23.812-2.165z" />
                    <path
                        d="M289.112 46.477l5.655-36.732-19.13 1.739-7.352 47.86-9.262-46.348-21.155 1.925-24.004 50.314 1.481-9.503-16.684 1.516.77-5.306 14.73-1.338 2.165-14.062-14.73 1.338.836-5.167 16.126-1.465 2.235-14.775-33.845 3.077-1.551.143-18.416 1.675-10.751 25.24c-1.188 2.724-2.165 5.433-2.583 8.157l-.419.04c.419-2.724.278-5.33-.07-7.916l-3.213-23.97-19.099 1.735-.867.08-34.53 3.14-7.706 50.18-9.09-17.226c-1.06-1.87-2.38-2.82-3.44-3.706l.09-.543c9.434-3.896 14.023-8.6 15.61-18.932 2.645-17.218-9.083-22.584-24.075-21.221L37.65 19.079 27.154 87.387l23.633-2.146 3.35-21.926.44-.04 7.231 20.964 25.333-2.3-.004.019 35.955-3.267 2.302-14.782-16.684 1.516.766-5.306 14.73-1.338 2.165-14.062-14.73 1.339.837-5.168 16.125-1.465 1.884-12.462 11.56 50.006 16.196-1.474 26.52-51.696-7.675 49.978 34.18-3.109 1.775-.162 18.474-1.679 2.583-6.882 14.453-1.315.7 6.585 18.083-1.643 2.165-.198 33.02-3.002 2.654-17.36-16.063 1.465zM67.19 40.593c-.794 5.077-4.85 6.07-8.817 6.43l-1.677.151 1.763-11.598 1.852-.167c4.147-.376 7.672-.07 6.879 5.184zm172.284 8.2l6.074-16.82.7-.064c0 2.265-.071 4.605.14 6.85l.766 9.338-7.68.697zM303.506 111.135l.461-3.022-18.728 1.703 6.593-42.838-22.308 2.027-8.961 58.357-11.299-56.511-24.668 2.24-27.998 58.687 1.727-11.092-19.459 1.77.895-6.185 17.18-1.56 2.525-16.399-17.18 1.56.977-6.026 18.806-1.711 2.607-17.23-41.279 3.754-3.634 23.665c-.997-16.43-14.144-22.049-31.903-20.437l-22.718 2.063-9.688 63.081 28.252-2.566c15.277-1.39 28.143-11.004 33.474-25.351l-3.475 22.623 39.868-3.624 2.063-.186 21.546-1.96 3.013-8.027 16.852-1.532.813 7.682 21.972-1.996 1.641-.15 25.06-2.277 16.973-18.532zm-166.528 1.845c-1.301 8.284-7.492 12.311-14.898 12.984l-1.383.127 3.744-24.428 1.383-.127c8.14-.737 12.538 2.574 11.154 11.444zm89.49-.38l7.085-19.618.813-.075c0 2.641-.082 5.37.164 7.987l.895 10.89-8.957.816z"
                        fillRule="nonzero"
            />
                </g>
                <g fill="#939598" fillRule="nonzero" opacity={0.5}>
                    <path d="M198.325 120.686l-19.459 1.77 1.067.835 18.243-1.659zM134.93 118.746c-2.677 4.435-7.433 6.731-12.854 7.222l-.16.016-.145.947.305-.028c3.865-.353 10.099-1.925 12.854-8.157zM286.318 110.651l17.505-1.591.144-.947-18.728 1.703zM199.462 98.308l-17.176 1.565 1.086.831 15.946-1.45zM291.688 67.924l.148-.946-22.312 2.031-.144.947zM227.8 113.412l6.621-.602 1-1.022-8.953.812zM204.531 75.848l.145-.946-41.282 3.753-.145.947zM127.856 82.817c14.242-1.295 24.235 2.063 29.23 9.583-4.839-8.763-15.688-11.745-29.23-10.517l-22.714 2.067-.145.946 22.86-2.079zM249.448 71.765l-.184-.914-24.668 2.24-.465.975z" />
                    <g>
                        <path d="M216.322 36.542l-14.73 1.338 1.087.832 13.499-1.224zM125.398 63.905l-16.684 1.517 1.067.835 15.469-1.406zM215.345 55.728l-16.684 1.516 1.067.836 15.472-1.406zM126.375 44.715l-14.73 1.339 1.087.835 13.499-1.227zM240.807 49.606l5.346-.483 1-1.026-7.679.697zM65.904 43.86c-1.685 2.293-4.631 2.902-7.531 3.164l-1.677.15 1.075.836.598-.056c3.72-.336 6.46-1.207 7.535-4.094zM37.648 19.997l29.186-2.653c9.747-.887 17.122 1.081 21.33 5.69.442.488.849.999 1.22 1.537-3.685-6.898-12.361-9.084-22.55-8.161l-29.186 2.653-.149.974c.05-.012.098-.036.149-.04zM166.981 22.294l53.668-4.878.144-.947-33.845 3.077-1.551.143-18.416 1.675-.473 1.112a.929.929 0 01.473-.182zM95.45 28.796l54.495-4.953c.043-.004.083.007.126.011l-.126-.946-19.099 1.734-.867.08-34.53 3.14-.148.974c.047-.012.094-.036.149-.04zM237.868 15.851l21.155-1.924c.066-.008.129.008.192.016l-.192-.95-21.155 1.924-.57 1.196c.16-.143.359-.242.57-.262zM275.637 12.418l18.986-1.726.144-.947-19.13 1.739-.149.974c.051-.012.098-.036.149-.04zM290.19 47.313l14.836-1.35.145-.947-16.059 1.461z" />
                    </g>
                </g>
                <g fill={secondaryColor} fillRule="nonzero">
                    <path d="M78.422 103.266l-2.107 11.009-4.791.435 2.106-11.008-4.432.404-.07.008-13.143 1.192-.778 4.047 4.358-.396-4.409 22.987 4.502-.412 4.405-22.987 4.291-.388-4.412 22.987 4.502-.408 2.302-11.983 4.791-.435-2.301 11.983 4.502-.412 5.186-27.03zM95.837 105.658l.777-4.043-12.053 1.097-5.186 27.03 12.561-1.14.778-4.047-8.059.732 1.524-7.935 5.808-.527.778-4.047-5.808.53 1.325-6.965z" />
                </g>
                <path
                    fill={secondaryColor}
                    fillRule="nonzero"
                    d="M91.12 133.96l-41.052 3.73-.657 3.773 41.056-3.734zM98.056 92.586l-40.645 3.695-.652 3.774 40.645-3.695z"
          />
                <path
                    d="M282.023 139.48s5.745-10.423-11.854-11.666l42.724-22.698-30.87 34.364z"
                    fill="url(#prefix__b)"
                    fillRule="nonzero"
          />
                <path
                    d="M282.703 138.72l30.19-33.604-37.667 18.457c10.842 7.714 9.196 12.984 7.477 15.146z"
                    fill="url(#prefix__c)"
                    fillRule="nonzero"
          />
                <path
                    d="M7.938 9.737c-1.591 1.41-3.045 1.85-4.862 3.22-.95.717-2.42 4.898-2.658 7.092-.297 2.716-.578 9.547 2.853 8.316 0 0 1.583-.935 1.626.76 0 0 .012 2.55 2.837 2.277.477-.048 1.255-.4 1.931-.59 1.548-.436 1.356.285 1.579 1.255.328 1.446-.18 5.267 4.389 3.192 5.46-2.546 18.13-7.405 21.975-12.628 5.976-8.114 6.55-26.737-6.534-21.265-6.288 2.637-17.83 3.54-23.136 8.371z"
                    fill="url(#prefix__d)"
                    opacity={0.02}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M7.965 9.785c-1.587 1.402-3.048 1.825-4.858 3.192-.946.712-2.392 4.858-2.63 7.04-.297 2.709-.575 9.52 2.845 8.296 0 0 1.575-.93 1.622.76 0 0 .012 2.543 2.83 2.274.472-.048 1.25-.396 1.926-.59 1.544-.436 1.352.285 1.571 1.25.329 1.442-.176 5.248 4.373 3.185 5.44-2.539 18.068-7.362 21.902-12.565 5.96-8.094 6.53-26.662-6.511-21.21-6.273 2.618-17.782 3.553-23.07 8.368z"
                    fill="url(#prefix__e)"
                    opacity={0.041}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M7.992 9.832c-1.579 1.398-3.048 1.802-4.854 3.16-.946.71-2.368 4.816-2.603 6.99-.293 2.7-.57 9.495 2.838 8.276 0 0 1.57-.93 1.614.756 0 0 .012 2.535 2.818 2.265.473-.047 1.246-.396 1.919-.586 1.54-.435 1.348.285 1.567 1.248.324 1.437-.176 5.23 4.361 3.171 5.421-2.53 18.005-7.317 21.824-12.505 5.948-8.074 6.51-26.59-6.488-21.154-6.25 2.614-17.724 3.58-22.996 8.38z"
                    fill="url(#prefix__f)"
                    opacity={0.061}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.02 9.88c-1.575 1.394-3.053 1.778-4.85 3.132-.942.71-2.341 4.772-2.58 6.942-.293 2.689-.567 9.472 2.83 8.252 0 0 1.567-.926 1.61.753 0 0 .012 2.526 2.81 2.257.473-.048 1.239-.396 1.91-.586 1.533-.432 1.345.285 1.564 1.243.325 1.43-.176 5.211 4.346 3.16 5.401-2.522 17.943-7.274 21.75-12.446 5.932-8.054 6.49-26.515-6.465-21.098-6.23 2.606-17.673 3.607-22.925 8.39z"
                    fill="url(#prefix__g)"
                    opacity={0.082}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.047 9.927c-1.571 1.39-3.056 1.755-4.846 3.105-.938.705-2.318 4.732-2.552 6.89-.293 2.681-.563 9.445 2.821 8.233 0 0 1.56-.923 1.603.752 0 0 .012 2.519 2.802 2.25.469-.048 1.235-.393 1.907-.583 1.528-.431 1.337.282 1.556 1.24.324 1.425-.176 5.195 4.33 3.148 5.385-2.51 17.876-7.235 21.67-12.387 5.918-8.034 6.473-26.444-6.44-21.043C24.692 4.126 13.284 5.16 8.047 9.927z"
                    fill="url(#prefix__h)"
                    opacity={0.102}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.074 9.975c-1.563 1.386-3.056 1.73-4.842 3.073-.934.705-2.29 4.688-2.529 6.843-.289 2.673-.558 9.42 2.814 8.212 0 0 1.556-.918 1.599.749 0 0 .012 2.506 2.79 2.241.47-.043 1.231-.392 1.9-.578 1.524-.432 1.332.281 1.551 1.235.325 1.422-.176 5.176 4.315 3.14 5.366-2.502 17.813-7.19 21.596-12.326 5.906-8.015 6.453-26.37-6.417-20.988-6.183 2.582-17.56 3.647-22.777 8.399z"
                    fill="url(#prefix__i)"
                    opacity={0.122}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.102 10.019c-1.56 1.378-3.06 1.702-4.839 3.045-.93.7-2.266 4.645-2.5 6.79-.29 2.662-.556 9.394 2.805 8.19 0 0 1.548-.915 1.595.744 0 0 .011 2.5 2.782 2.234.465-.044 1.228-.392 1.892-.578 1.516-.428 1.329.28 1.548 1.227.32 1.418-.176 5.16 4.299 3.129 5.346-2.495 17.75-7.148 21.518-12.264 5.89-7.995 6.437-26.298-6.39-20.932-6.167 2.582-17.509 3.682-22.71 8.415z"
                    fill="url(#prefix__j)"
                    opacity={0.143}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.133 10.066c-1.556 1.374-3.06 1.68-4.834 3.018-.927.696-2.244 4.6-2.474 6.743-.29 2.653-.551 9.37 2.798 8.17 0 0 1.544-.915 1.587.744 0 0 .011 2.49 2.77 2.225.466-.043 1.224-.388 1.888-.574 1.513-.427 1.325.281 1.54 1.224.32 1.41-.176 5.14 4.287 3.116 5.327-2.487 17.689-7.108 21.445-12.204 5.874-7.975 6.417-26.223-6.367-20.877-6.151 2.566-17.462 3.699-22.64 8.415z"
                    fill="url(#prefix__k)"
                    opacity={0.163}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.16 10.114c-1.547 1.37-3.064 1.655-4.83 2.99-.926.696-2.216 4.561-2.45 6.692-.29 2.645-.552 9.345 2.786 8.149 0 0 1.54-.91 1.583.74 0 0 .011 2.483 2.763 2.218.465-.044 1.22-.388 1.88-.574 1.504-.424 1.32.277 1.536 1.22.32 1.405-.172 5.124 4.271 3.104 5.308-2.479 17.626-7.064 21.366-12.145 5.863-7.96 6.398-26.151-6.343-20.821-6.124 2.558-17.399 3.726-22.562 8.427z"
                    fill="url(#prefix__l)"
                    opacity={0.184}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.188 10.161c-1.544 1.366-3.064 1.632-4.827 2.958-.922.693-2.192 4.518-2.423 6.64-.285 2.634-.547 9.319 2.779 8.127 0 0 1.532-.907 1.575.736 0 0 .011 2.475 2.751 2.21.461-.044 1.215-.388 1.872-.57 1.5-.424 1.313.277 1.528 1.215.317 1.402-.172 5.105 4.256 3.097 5.292-2.467 17.56-7.021 21.288-12.086 5.847-7.94 6.378-26.076-6.32-20.765-6.092 2.554-17.336 3.754-22.48 8.438z"
                    fill="url(#prefix__m)"
                    opacity={0.204}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.215 10.209c-1.536 1.362-3.068 1.607-4.823 2.93-.918.693-2.165 4.475-2.395 6.593-.286 2.626-.544 9.294 2.77 8.106 0 0 1.529-.903 1.572.737 0 0 .011 2.463 2.743 2.201.461-.043 1.212-.384 1.868-.57 1.497-.424 1.31.277 1.524 1.212.317 1.398-.172 5.088 4.24 3.085 5.273-2.46 17.498-6.978 21.214-12.027 5.831-7.92 6.36-26.004-6.296-20.71-6.08 2.542-17.29 3.774-22.417 8.443z"
                    fill="url(#prefix__n)"
                    opacity={0.225}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.242 10.256C6.71 11.61 5.174 11.84 3.424 13.16c-.915.689-2.142 4.435-2.373 6.542-.285 2.617-.539 9.27 2.763 8.086 0 0 1.525-.9 1.568.732 0 0 .011 2.455 2.731 2.194.458-.044 1.208-.384 1.86-.566 1.49-.42 1.306.277 1.521 1.207.317 1.39-.172 5.07 4.225 3.073 5.252-2.45 17.434-6.937 21.135-11.962 5.82-7.9 6.34-25.93-6.272-20.655-6.054 2.526-17.232 3.793-22.34 8.446z"
                    fill="url(#prefix__o)"
                    opacity={0.245}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.27 10.304c-1.528 1.35-3.072 1.56-4.815 2.87-.91.686-2.114 4.392-2.345 6.495-.285 2.605-.535 9.242 2.755 8.062 0 0 1.517-.899 1.56.733 0 0 .011 2.447 2.724 2.186.457-.044 1.203-.384 1.852-.567 1.485-.42 1.301.274 1.513 1.204.312 1.386-.172 5.053 4.209 3.061 5.233-2.443 17.372-6.894 21.06-11.903 5.805-7.88 6.32-25.858-6.248-20.6C24.5 4.368 13.358 5.667 8.27 10.304z"
                    fill="url(#prefix__p)"
                    opacity={0.265}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.297 10.351c-1.52 1.347-3.076 1.537-4.81 2.843-.908.685-2.092 4.348-2.318 6.443-.282 2.598-.532 9.219 2.747 8.043 0 0 1.512-.895 1.555.728 0 0 .012 2.44 2.713 2.178.453-.043 1.2-.38 1.844-.562 1.482-.42 1.298.273 1.509 1.2.313 1.382-.172 5.033 4.197 3.053 5.218-2.435 17.306-6.85 20.983-11.844 5.792-7.86 6.3-25.783-6.225-20.544C24.477 4.399 13.37 5.73 8.297 10.35z"
                    fill="url(#prefix__q)"
                    opacity={0.286}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.324 10.399c-1.516 1.342-3.075 1.508-4.803 2.815-.906.681-2.063 4.309-2.294 6.391-.281 2.586-.527 9.191 2.74 8.023 0 0 1.508-.89 1.547.725 0 0 .012 2.431 2.705 2.17.453-.044 1.192-.38 1.84-.562 1.474-.416 1.294.273 1.505 1.195.313 1.379-.168 5.018 4.182 3.042 5.198-2.424 17.243-6.811 20.909-11.785 5.776-7.84 6.28-25.712-6.202-20.492-6 2.506-17.075 3.872-22.129 8.478z"
                    fill="url(#prefix__r)"
                    opacity={0.306}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.352 10.446c-1.513 1.339-3.08 1.485-4.8 2.784-.902.681-2.04 4.265-2.266 6.344-.282 2.578-.524 9.167 2.732 8.003 0 0 1.5-.887 1.543.724 0 0 .012 2.42 2.693 2.163.453-.044 1.188-.38 1.833-.559 1.47-.416 1.286.273 1.497 1.192.313 1.37-.168 4.997 4.166 3.03 5.178-2.416 17.18-6.768 20.83-11.722 5.761-7.82 6.262-25.636-6.178-20.437-5.972 2.49-17.013 3.893-22.05 8.478z"
                    fill="url(#prefix__s)"
                    opacity={0.327}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.38 10.49c-1.505 1.33-3.08 1.461-4.796 2.756-.9.677-2.017 4.221-2.24 6.292-.281 2.57-.52 9.144 2.724 7.98 0 0 1.497-.884 1.536.72 0 0 .012 2.412 2.685 2.154.45-.043 1.184-.376 1.825-.558 1.466-.412 1.282.27 1.493 1.188.31 1.366-.168 4.981 4.15 3.017 5.16-2.407 17.119-6.724 20.753-11.662 5.75-7.8 6.242-25.565-6.151-20.381-5.952 2.49-16.962 3.928-21.98 8.494z"
                    fill="url(#prefix__t)"
                    opacity={0.347}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.407 10.537c-1.501 1.327-3.084 1.438-4.792 2.729-.895.673-1.99 4.177-2.216 6.244-.277 2.559-.516 9.116 2.716 7.96 0 0 1.49-.883 1.532.717 0 0 .012 2.403 2.674 2.146.449-.044 1.18-.376 1.82-.555 1.459-.411 1.279.27 1.486 1.184.309 1.363-.168 4.962 4.135 3.01 5.143-2.4 17.055-6.68 20.678-11.603 5.733-7.785 6.222-25.49-6.128-20.326-5.929 2.475-16.903 3.944-21.905 8.494z"
                    fill="url(#prefix__u)"
                    opacity={0.367}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.434 10.585c-1.493 1.322-3.084 1.413-4.788 2.697-.89.673-1.965 4.138-2.188 6.193-.278 2.55-.512 9.092 2.708 7.94 0 0 1.485-.88 1.528.716 0 0 .012 2.396 2.666 2.138.445-.043 1.176-.372 1.813-.554 1.454-.412 1.274.27 1.481 1.18.309 1.358-.168 4.942 4.12 2.998 5.123-2.392 16.988-6.641 20.6-11.543 5.717-7.766 6.202-25.419-6.105-20.271-5.91 2.47-16.852 3.972-21.835 8.506z"
                    fill="url(#prefix__v)"
                    opacity={0.388}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.461 10.632c-1.489 1.319-3.087 1.39-4.783 2.67-.891.669-1.939 4.094-2.162 6.145-.277 2.542-.508 9.064 2.7 7.916 0 0 1.482-.875 1.521.713 0 0 .012 2.388 2.658 2.13.445-.043 1.172-.372 1.805-.55 1.45-.408 1.27.269 1.478 1.172.305 1.35-.168 4.926 4.107 2.986 5.104-2.38 16.927-6.598 20.526-11.484 5.706-7.746 6.183-25.343-6.081-20.215-5.894 2.463-16.801 4-21.769 8.517z"
                    fill="url(#prefix__w)"
                    opacity={0.408}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.489 10.68c-1.486 1.31-3.088 1.366-4.78 2.641-.887.67-1.915 4.051-2.138 6.094-.277 2.53-.508 9.04 2.689 7.896 0 0 1.473-.87 1.516.71 0 0 .012 2.375 2.646 2.122.446-.044 1.169-.372 1.802-.55 1.442-.409 1.266.265 1.47 1.168.304 1.346-.165 4.906 4.091 2.973 5.085-2.372 16.864-6.553 20.448-11.42 5.69-7.726 6.163-25.272-6.058-20.16C24.313 4.61 13.436 6.177 8.49 10.68z"
                    fill="url(#prefix__x)"
                    opacity={0.429}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.52 10.727c-1.477 1.307-3.092 1.343-4.776 2.614-.883.665-1.888 4.011-2.11 6.043-.274 2.522-.505 9.016 2.68 7.876 0 0 1.47-.867 1.51.709 0 0 .01 2.368 2.637 2.114.442-.043 1.165-.368 1.794-.546 1.438-.408 1.259.265 1.466 1.164.304 1.343-.164 4.89 4.076 2.966 5.069-2.364 16.801-6.514 20.373-11.36 5.675-7.707 6.144-25.198-6.034-20.105C24.289 4.64 13.444 6.24 8.52 10.727z"
                    fill="url(#prefix__y)"
                    opacity={0.449}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.547 10.775c-1.473 1.303-3.095 1.319-4.772 2.582-.879.661-1.864 3.968-2.083 5.995-.273 2.515-.5 8.989 2.673 7.853 0 0 1.466-.868 1.505.704 0 0 .012 2.36 2.626 2.107.442-.044 1.161-.368 1.786-.546 1.435-.404 1.255.265 1.462 1.16.305 1.338-.164 4.87 4.06 2.954 5.05-2.356 16.74-6.47 20.296-11.302 5.663-7.686 6.128-25.125-6.01-20.049-5.824 2.436-16.634 4.071-21.543 8.542z"
                    fill="url(#prefix__z)"
                    opacity={0.469}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.575 10.822c-1.47 1.3-3.096 1.291-4.768 2.555-.876.66-1.837 3.924-2.06 5.943-.274 2.503-.496 8.966 2.665 7.833 0 0 1.458-.863 1.501.7 0 0 .012 2.353 2.619 2.1.437-.044 1.156-.369 1.782-.543 1.426-.404 1.25.265 1.454 1.156.3 1.335-.165 4.855 4.045 2.943 5.03-2.349 16.672-6.427 20.22-11.242 5.648-7.667 6.109-25.05-5.987-19.994-5.804 2.427-16.582 4.095-21.471 8.55z"
                    fill="url(#prefix__A)"
                    opacity={0.49}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.602 10.87c-1.462 1.295-3.1 1.267-4.764 2.526-.872.658-1.814 3.881-2.032 5.897-.274 2.494-.493 8.941 2.657 7.813 0 0 1.454-.86 1.493.7 0 0 .012 2.345 2.607 2.091.438-.043 1.149-.364 1.774-.542 1.423-.4 1.247.261 1.45 1.152.301 1.327-.164 4.835 4.033 2.93 5.01-2.336 16.61-6.383 20.143-11.182 5.632-7.647 6.09-24.98-5.964-19.938C24.22 4.728 13.475 6.43 8.602 10.87z"
                    fill="url(#prefix__B)"
                    opacity={0.51}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.63 10.913c-1.458 1.287-3.1 1.244-4.76 2.495-.872.654-1.79 3.841-2.01 5.845-.27 2.487-.488 8.914 2.65 7.79 0 0 1.45-.856 1.49.696 0 0 .01 2.332 2.598 2.083.434-.044 1.145-.364 1.767-.539 1.418-.4 1.242.262 1.442 1.149.3 1.322-.165 4.819 4.017 2.922 4.991-2.328 16.548-6.344 20.065-11.12 5.62-7.626 6.07-24.903-5.94-19.882-5.753 2.408-16.466 4.142-21.32 8.561z"
                    fill="url(#prefix__C)"
                    opacity={0.531}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.657 10.961c-1.45 1.283-3.103 1.22-4.757 2.467-.867.653-1.762 3.798-1.981 5.793-.27 2.475-.485 8.89 2.642 7.77 0 0 1.442-.852 1.481.693 0 0 .012 2.324 2.587 2.075.434-.044 1.141-.365 1.763-.539 1.41-.4 1.235.261 1.438 1.145.297 1.318-.164 4.799 4.002 2.91 4.975-2.32 16.485-6.3 19.99-11.06 5.605-7.61 6.05-24.832-5.917-19.827-5.733 2.403-16.41 4.17-21.248 8.573z"
                    fill="url(#prefix__D)"
                    opacity={0.551}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.684 11.009c-1.446 1.279-3.103 1.195-4.752 2.439-.864.65-1.74 3.754-1.954 5.746-.27 2.467-.481 8.862 2.634 7.75 0 0 1.438-.852 1.477.692 0 0 .012 2.317 2.58 2.071.433-.043 1.137-.36 1.754-.534 1.407-.396 1.231.26 1.434 1.14.297 1.315-.16 4.784 3.987 2.899 4.955-2.313 16.422-6.257 19.912-11 5.589-7.592 6.03-24.758-5.89-19.773-5.717 2.38-16.363 4.182-21.182 8.57z"
                    fill="url(#prefix__E)"
                    opacity={0.571}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.711 11.056c-1.442 1.275-3.107 1.172-4.748 2.408-.86.65-1.712 3.714-1.93 5.694-.27 2.46-.478 8.838 2.626 7.726 0 0 1.43-.848 1.473.689 0 0 .012 2.308 2.568 2.063.43-.044 1.133-.36 1.747-.535 1.403-.396 1.227.258 1.426 1.137.297 1.307-.16 4.764 3.97 2.887C20.78 30.82 32.2 26.908 35.683 22.183c5.577-7.571 6.01-24.686-5.866-19.716-5.69 2.384-16.301 4.217-21.105 8.589z"
                    fill="url(#prefix__F)"
                    opacity={0.592}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.739 11.104c-1.435 1.27-3.111 1.148-4.745 2.38-.856.645-1.688 3.67-1.903 5.646-.266 2.447-.473 8.815 2.618 7.706 0 0 1.427-.843 1.466.685 0 0 .012 2.301 2.56 2.055.43-.04 1.13-.36 1.743-.53 1.395-.392 1.223.257 1.422 1.132.297 1.303-.16 4.748 3.956 2.88 4.916-2.297 16.293-6.174 19.76-10.883 5.56-7.551 5.99-24.61-5.843-19.66-5.675 2.368-16.25 4.233-21.034 8.589z"
                    fill="url(#prefix__G)"
                    opacity={0.612}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.766 11.151c-1.43 1.263-3.11 1.125-4.74 2.352-.856.642-1.662 3.628-1.876 5.596-.266 2.439-.47 8.787 2.61 7.686 0 0 1.423-.84 1.462.685 0 0 .012 2.289 2.548 2.047.426-.04 1.126-.356 1.735-.53 1.392-.393 1.22.257 1.415 1.128.293 1.299-.16 4.728 3.943 2.867 4.901-2.285 16.231-6.13 19.686-10.818 5.55-7.532 5.972-24.54-5.82-19.606-5.655 2.352-16.195 4.253-20.963 8.593z"
                    fill="url(#prefix__H)"
                    opacity={0.633}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.793 11.199c-1.422 1.259-3.114 1.096-4.736 2.32-.852.642-1.638 3.588-1.853 5.544-.266 2.431-.465 8.763 2.603 7.662 0 0 1.415-.835 1.454.681 0 0 .012 2.281 2.54 2.04.426-.04 1.122-.357 1.728-.527 1.383-.392 1.215.258 1.41 1.12.294 1.296-.16 4.713 3.928 2.856 4.882-2.277 16.168-6.086 19.608-10.76 5.534-7.511 5.952-24.463-5.796-19.55C24.05 4.943 13.542 6.876 8.793 11.2z"
                    fill="url(#prefix__I)"
                    opacity={0.653}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.82 11.246c-1.418 1.255-3.114 1.073-4.732 2.293-.848.637-1.61 3.544-1.825 5.496-.262 2.42-.465 8.736 2.591 7.643 0 0 1.41-.836 1.45.677 0 0 .012 2.273 2.529 2.031.425-.04 1.117-.356 1.723-.526 1.38-.388 1.208.253 1.407 1.116.293 1.287-.16 4.693 3.912 2.844 4.862-2.27 16.106-6.047 19.53-10.7 5.518-7.492 5.932-24.393-5.773-19.499C24.028 4.97 13.55 6.938 8.82 11.246z"
                    fill="url(#prefix__J)"
                    opacity={0.673}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.848 11.294c-1.415 1.251-3.119 1.049-4.729 2.265-.844.637-1.586 3.5-1.798 5.445-.261 2.411-.46 8.711 2.584 7.622 0 0 1.407-.831 1.442.678 0 0 .012 2.265 2.52 2.023.423-.04 1.115-.352 1.716-.523 1.376-.388 1.204.254 1.4 1.113.289 1.283-.157 4.677 3.896 2.835 4.842-2.26 16.04-6.003 19.455-10.64C40.84 14.64 41.247-2.206 29.585 2.67c-5.58 2.332-16.024 4.332-20.737 8.625z"
                    fill="url(#prefix__K)"
                    opacity={0.694}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.875 11.337c-1.406 1.248-3.118 1.026-4.725 2.238-.84.633-1.563 3.457-1.774 5.397-.262 2.404-.457 8.688 2.576 7.599 0 0 1.399-.828 1.438.673 0 0 .012 2.257 2.509 2.016.422-.04 1.11-.353 1.708-.523 1.368-.388 1.2.253 1.395 1.109.29 1.279-.156 4.657 3.88 2.823 4.827-2.253 15.978-5.96 19.378-10.58 5.49-7.457 5.893-24.247-5.726-19.388-5.553 2.332-15.965 4.363-20.659 8.636z"
                    fill="url(#prefix__L)"
                    opacity={0.714}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.907 11.385c-1.403 1.24-3.123 1.002-4.721 2.205-.837.63-1.536 3.418-1.747 5.346-.262 2.392-.454 8.66 2.567 7.58 0 0 1.396-.824 1.435.669 0 0 .011 2.245 2.5 2.007.42-.04 1.103-.352 1.701-.518 1.364-.384 1.196.253 1.391 1.104.29 1.276-.156 4.637 3.87 2.812 4.806-2.241 15.914-5.92 19.302-10.518 5.475-7.436 5.874-24.17-5.702-19.332-5.546 2.32-15.922 4.392-20.596 8.645z"
                    fill="url(#prefix__M)"
                    opacity={0.735}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.934 11.432c-1.399 1.236-3.123.978-4.717 2.178-.836.63-1.513 3.374-1.72 5.299-.258 2.383-.45 8.636 2.56 7.559 0 0 1.388-.82 1.427.67 0 0 .011 2.237 2.493 1.999.418-.04 1.098-.349 1.696-.519 1.36-.384 1.192.25 1.384 1.101.289 1.267-.157 4.621 3.853 2.8 4.788-2.234 15.852-5.877 19.225-10.458 5.463-7.417 5.854-24.1-5.679-19.277-5.522 2.308-15.867 4.411-20.522 8.648z"
                    fill="url(#prefix__N)"
                    opacity={0.755}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.961 11.48c-1.39 1.231-3.126.954-4.713 2.15-.832.626-1.485 3.33-1.696 5.247-.258 2.376-.445 8.613 2.552 7.536 0 0 1.384-.82 1.423.665 0 0 .011 2.23 2.481 1.992.415-.04 1.095-.349 1.689-.515 1.352-.38 1.184.25 1.38 1.097.285 1.263-.157 4.601 3.837 2.791 4.768-2.225 15.785-5.832 19.15-10.398C40.512 14.648 40.9-1.98 29.413 2.823 23.91 5.124 13.6 7.258 8.961 11.48z"
                    fill="url(#prefix__O)"
                    opacity={0.775}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M8.989 11.527c-1.388 1.228-3.13.93-4.71 2.119-.828.625-1.461 3.29-1.668 5.195-.258 2.364-.442 8.585 2.544 7.516 0 0 1.38-.816 1.415.661 0 0 .011 2.222 2.474 1.984.414-.04 1.09-.348 1.68-.514 1.348-.38 1.18.249 1.372 1.092.285 1.26-.156 4.586 3.822 2.78 4.752-2.217 15.723-5.79 19.072-10.339 5.432-7.377 5.82-23.953-5.628-19.166-5.475 2.297-15.754 4.467-20.373 8.672z"
                    fill="url(#prefix__P)"
                    opacity={0.796}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.016 11.575c-1.38 1.223-3.13.903-4.705 2.09-.825.622-1.435 3.248-1.642 5.148-.258 2.357-.437 8.562 2.537 7.497 0 0 1.371-.812 1.41.66 0 0 .012 2.214 2.463 1.977.414-.04 1.086-.345 1.676-.511 1.345-.38 1.177.25 1.368 1.089.285 1.255-.152 4.566 3.807 2.768 4.733-2.21 15.66-5.75 18.997-10.28 5.421-7.357 5.8-23.878-5.604-19.11-5.46 2.28-15.703 4.482-20.307 8.672z"
                    fill="url(#prefix__Q)"
                    opacity={0.816}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.044 11.622c-1.376 1.216-3.135.88-4.702 2.063-.82.618-1.41 3.204-1.618 5.097-.254 2.348-.434 8.533 2.529 7.472 0 0 1.367-.808 1.407.657 0 0 .011 2.202 2.454 1.969.41-.04 1.082-.345 1.669-.511 1.336-.376 1.172.245 1.364 1.085.281 1.247-.153 4.55 3.79 2.756 4.714-2.198 15.598-5.706 18.92-10.217 5.405-7.337 5.78-23.807-5.58-19.055-5.437 2.273-15.65 4.51-20.233 8.684z"
                    fill="url(#prefix__R)"
                    opacity={0.837}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.07 11.67c-1.37 1.212-3.133.855-4.697 2.031-.82.618-1.383 3.164-1.59 5.05-.254 2.335-.43 8.509 2.52 7.452 0 0 1.364-.804 1.4.653 0 0 .011 2.194 2.442 1.96.41-.04 1.079-.344 1.661-.507 1.333-.376 1.169.246 1.356 1.081.282 1.244-.152 4.53 3.78 2.749 4.693-2.19 15.535-5.663 18.84-10.158 5.39-7.317 5.762-23.731-5.557-19-5.408 2.262-15.585 4.53-20.154 8.689z"
                    fill="url(#prefix__S)"
                    opacity={0.857}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.098 11.717c-1.364 1.208-3.138.832-4.693 2.004-.817.614-1.36 3.12-1.564 4.997-.254 2.329-.426 8.487 2.513 7.433 0 0 1.356-.804 1.395.654 0 0 .012 2.185 2.435 1.952.407-.04 1.075-.34 1.657-.507 1.33-.376 1.165.245 1.353 1.077.28 1.24-.153 4.514 3.763 2.736 4.674-2.182 15.469-5.623 18.767-10.097 5.378-7.299 5.741-23.66-5.534-18.945-5.397 2.254-15.543 4.554-20.092 8.696z"
                    fill="url(#prefix__T)"
                    opacity={0.878}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.126 11.76c-1.36 1.205-3.139.809-4.686 1.977-.813.61-1.337 3.077-1.54 4.946-.254 2.32-.426 8.458 2.505 7.409 0 0 1.352-.8 1.387.65 0 0 .012 2.177 2.424 1.944.406-.04 1.07-.341 1.649-.503 1.32-.373 1.157.245 1.348 1.073.281 1.235-.152 4.494 3.748 2.724 4.659-2.174 15.406-5.58 18.689-10.038 5.362-7.282 5.722-23.585-5.51-18.889-5.37 2.25-15.485 4.586-20.014 8.708z"
                    fill="url(#prefix__U)"
                    opacity={0.898}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.153 11.808c-1.356 1.196-3.142.784-4.682 1.945-.809.61-1.31 3.033-1.513 4.898-.25 2.309-.422 8.435 2.494 7.39 0 0 1.348-.797 1.383.645 0 0 .012 2.17 2.416 1.936.406-.04 1.067-.34 1.641-.503 1.317-.372 1.153.242 1.34 1.065.278 1.228-.152 4.48 3.733 2.713 4.639-2.166 15.343-5.536 18.615-9.979 5.346-7.262 5.702-23.514-5.487-18.833-5.347 2.249-15.426 4.617-19.94 8.723z"
                    fill="url(#prefix__V)"
                    opacity={0.918}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.18 11.856c-1.348 1.192-3.142.76-4.678 1.917-.805.605-1.286 2.993-1.485 4.846-.25 2.301-.418 8.411 2.486 7.37 0 0 1.34-.792 1.38.645 0 0 .011 2.162 2.403 1.929.402-.04 1.059-.337 1.637-.5 1.314-.367 1.15.242 1.337 1.062.277 1.224-.152 4.459 3.717 2.705 4.62-2.155 15.28-5.493 18.536-9.916 5.335-7.243 5.683-23.439-5.463-18.778-5.331 2.23-15.375 4.63-19.87 8.72z"
                    fill="url(#prefix__W)"
                    opacity={0.939}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.208 11.903c-1.345 1.188-3.146.737-4.675 1.89-.8.605-1.258 2.95-1.461 4.799-.25 2.293-.414 8.383 2.478 7.345 0 0 1.336-.788 1.371.642 0 0 .012 2.15 2.396 1.92.403-.04 1.055-.336 1.63-.499 1.305-.368 1.145.242 1.329 1.058.277 1.22-.149 4.443 3.705 2.692 4.6-2.146 15.218-5.452 18.462-9.856 5.319-7.223 5.663-23.367-5.44-18.722-5.308 2.221-15.317 4.657-19.795 8.731z"
                    fill="url(#prefix__X)"
                    opacity={0.959}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.235 11.951c-1.337 1.184-3.15.709-4.67 1.861-.801.602-1.235 2.907-1.435 4.748-.25 2.281-.41 8.36 2.47 7.326 0 0 1.33-.788 1.368.641 0 0 .012 2.143 2.384 1.913.399-.04 1.052-.337 1.622-.495 1.302-.368 1.141.242 1.325 1.053.274 1.216-.148 4.424 3.69 2.681 4.584-2.138 15.151-5.409 18.384-9.797C39.68 14.68 40.016-1.41 28.96 3.215c-5.288 2.21-15.266 4.677-19.725 8.736z"
                    fill="url(#prefix__Y)"
                    opacity={0.98}
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M9.266 11.998c-1.332 1.18-3.15.686-4.666 1.83-.797.598-1.208 2.867-1.41 4.7-.247 2.273-.407 8.332 2.461 7.306 0 0 1.325-.784 1.36.638 0 0 .012 2.134 2.376 1.905.4-.04 1.048-.333 1.618-.495 1.298-.365 1.134.237 1.321 1.049.274 1.208-.148 4.407 3.674 2.669 4.565-2.13 15.09-5.366 18.31-9.737 5.292-7.184 5.624-23.221-5.39-18.612-5.272 2.202-15.214 4.704-19.654 8.747z"
                    fill="url(#prefix__Z)"
                    transform="translate(5.08 91.87)"
          />
                <path
                    d="M15.125 99.184l2.18-1.074c-.343-1.987-1.086-1.528-1.461-.87-.121.217-.633 1.706-.72 1.944z"
                    fill="#939598"
          />
                <path
                    d="M11.412 101.524l7.164-2.709c.95 1.79 1.82 9.065 2.098 11.745-3.994 1.386-8.578 2.867-10.349 3.362-1.657.523-.093-11.396 1.087-12.398z"
                    fill="url(#prefix__aa)"
          />
                <path
                    d="M12.92 100.95l.532-.202c-.97 6.19-.543 10.85-.356 12.323l-.98.309c-.493-5.053.492-10.787.804-12.43z"
                    fill="url(#prefix__ab)"
          />
                <path
                    d="M13.51 99.71l4.542-2.158c1.41 5.164 2.208 10.57 2.575 17.368-2.935 1.137-4.596 1.727-6.155 2.071-2.79.875-2.208-15.582-.961-17.28z"
                    fill="url(#prefix__ac)"
          />
                <path
                    d="M16.508 98.289l1.208-.575c1.258 5.529 2.345 15.777 2.509 17.36-.688.266-1.302.5-1.86.706.644-10.459-1.157-15.84-1.857-17.491z"
                    fill="url(#prefix__ad)"
          />
                <path
                    d="M15.715 97.548c.324-1.164 1.415-1.477 4.377-2.716l17.204-7.223c3.888-1.956 5.753 20.694 8.664 20.013-.18.19-1.711.788-2.438 1.117-8.016 3.611-18.38 8.593-22.164 9.987-.547.202-1.117.313-1.239-.31-1.328-6.596-1.43-14.552-3.091-20.76-.262-.963-.664-1.304-1.313-.108z"
                    fill="url(#prefix__ae)"
          />
                <path
                    d="M12.655 113.285c-.32.103-.645.202-.966.3-.508.155-.941.286-1.278.38-.168.048-.293.064-.418-.035-.262-.21-.398-.855-.441-1.75-.043-.887.007-2.02.117-3.215.277-2.97.938-6.336 1.548-7.295.101-.158.144-.198.293-.253l1.602-.606-.059.162-1.516.575c-.121.043-.16.083-.235.206-.566.902-1.219 4.245-1.496 7.187-.11 1.192-.16 2.32-.118 3.195.04.868.165 1.485.403 1.672.098.075.191.051.313.015.344-.099.781-.23 1.29-.384.316-.095.629-.194.945-.293.121-.035.125.103.016.139z"
                    fill="url(#prefix__af)"
                    fillRule="nonzero"
          />
                <path
                    d="M19.615 115.384c-1.157.44-2.098.784-2.923 1.057-.758.25-1.419.44-2.052.586-.137.032-.277.075-.406.071a.703.703 0 01-.352-.107c-.418-.261-.735-.982-.965-2-.227-1.005-.372-2.3-.446-3.706-.242-4.514.23-10.236.926-11.496.082-.146.184-.178.32-.241l3.432-1.628.031.131-3.501 1.663c-.063.032-.114.044-.153.111-.684 1.168-1.149 6.89-.906 11.417.074 1.401.218 2.688.445 3.686.223.99.528 1.691.922 1.937.09.055.184.087.286.09.113.005.246-.035.363-.063a23.873 23.873 0 002.056-.586c.82-.27 1.759-.614 2.915-1.053l.008.13z"
                    fill="url(#prefix__ag)"
                    fillRule="nonzero"
          />
                <path
                    d="M15.617 97.627l.035-.11c.309-1.05 1.204-1.422 3.443-2.345.29-.119.602-.25.985-.408l16.974-7.123.37-.123a2.32 2.32 0 00-.19.107c-.051.035-.079.114-.118.134L20.104 94.9l-.985.408c-2.15.887-3.025 1.247-3.318 2.186-.016.04-.235.305-.184.134z"
                    fill="url(#prefix__ah)"
                    fillRule="nonzero"
          />
                <path
                    d="M46.003 107.666c-.144.15-.95.49-1.669.796-.297.126-.582.245-.8.344h-.005c-2.755 1.244-5.8 2.65-8.758 4.02-5.616 2.597-10.923 5.052-13.405 5.967-.281.103-.574.186-.813.158-.25-.027-.438-.166-.508-.502v-.004c-.586-2.907-.93-6.071-1.278-9.235-.442-4.023-.88-8.046-1.81-11.523-.105-.388-.23-.67-.382-.784-.13-.1-.293-.056-.489.182-.094.11-.172.23-.238.348-.067.123-.121.25-.172.38v.004l-.055.147-.031.087v.004c-.055.154-.114.329-.172.487-.028.075-.051.15-.078.222-.036.016-.098.047-.165.08.032-.096.07-.203.11-.314.055-.154.11-.317.172-.49v-.005l.031-.087c.016-.047.035-.095.055-.15v-.004c.05-.139.11-.274.184-.404s.156-.262.257-.384c.27-.325.497-.377.692-.234.172.127.309.432.418.84.93 3.484 1.372 7.511 1.814 11.539.348 3.16.692 6.324 1.274 9.222v.004c.059.285.215.4.414.42.211.024.48-.051.747-.15 2.474-.911 7.777-3.366 13.393-5.964a986.875 986.875 0 018.758-4.02h.004c.211-.094.5-.217.805-.348.528-.222 1.098-.463 1.403-.622a.765.765 0 00.2-.126l.015.016.082.083z"
                    fill="url(#prefix__ai)"
                    fillRule="nonzero"
          />
            </g>
        </svg>
    )
}

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: 315,
    height: 158,
    eventColor: '#FFFFFF'
}

export default withTheme(themes)(EventLogo)
