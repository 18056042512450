// @flow
import * as React from 'react'

import styles from './styles.module.scss'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'

import Brands from 'components/Brands'
import CashbackAndRates from 'components/CashbackAndRates'

const BrandsFooter = ({ theme }) => {
    return (
        <div className={styles.summaryFooter} style={{
            '--label-color': theme.labelColor,
            '--label-line-color': theme.labelLineColor
        }}>
            <Brands />
            <CashbackAndRates className={styles.cashbackAndRates} />
        </div>
    )
}

export default withTheme(supportedThemes)(BrandsFooter)
