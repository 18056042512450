// @flow
import * as React from 'react'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

function Sidebar (props) {
    const { theme, title, titleClassname } = props
    return (
        <div className={cn(styles.container, props.className)} style={{
            backgroundColor: theme.bgColor,
            backgroundImage: `url(${theme.bgImage})`
        }}>
            {title && <div className={cn(styles.sidebarTitle, titleClassname)} style={{
                color: theme.titleColor
            }}>{title}</div>}
            {props.children}
        </div>
    )
}

export default withTheme(supportedThemes)(Sidebar)
