// @flow
import React from 'react'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './Label.module.scss'

type Props = {
    children: React.Children,
    for?: string,
    isFloating: boolean
}

function Label (props: Props) {
    return (
        <label
            style={{
                '--field-color': props.theme.fieldColor
            }}
            className={cn(styles.label, { [styles.isFloating]: props.isFloating })}
            htmlFor={props.for}>
            {props.children}
        </label>
    )
}

export default withTheme(supportedThemes)(Label)

Label.defaultProps = {
    isFloating: false
}
