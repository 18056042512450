// @flow
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import AbsoluteTime from 'components/AbsoluteTime'
import Countdown from 'components/Countdown'
import cn from 'classnames'

type Props = {
    date: Date
};

class TopBar extends Component<Props, { formattedDate: { [string]: string } }> {
    static defaultProps = {
        date: new Date()
    };

    render () {
        const { t, theme } = this.props

        return (
            <>
                <div className={styles.container} style={{ backgroundColor: theme.background }}>
                    {theme.showLogo &&
                        <div className={cn(styles.logo, {
                            [styles.wideLogo]: theme.wideLogo
                        })} style={{ '--logo-container-size': theme.logoContainerSize, backgroundColor: theme.alternateBackground, ...theme.logoStyles || {} }}>
                            <img src={theme.logo} alt="" style={{ ...theme.logoImgStyles || {} }} />
                        </div>
                    }
                    <div className={cn(styles.expiryText, {
                        [styles.showLogo]: !theme.showLogo
                    })} style={{ color: theme.color }}>
                        <span>{t('event_expiry')}&nbsp;<strong style={{
                            color: theme.eventExpiryColor
                        }}><AbsoluteTime dateFormat='long' date={this.props.date} /></strong></span>
                        <div className={styles.expiryTextRight} >
                            <Countdown date={this.props.date} />
                        </div>
                    </div>
                </div>

                <div className={styles.spacer} />
            </>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
