import React from 'react'
import ReactDOM from 'react-dom'
import delay from 'delay'

import { useTransition, animated } from 'react-spring'

import styles from './styles.module.scss'

export const ModalVideoOverlay = ({ onClickOverlay, show }) => {
    const transitions = useTransition(show, null, {
        from: { opacity: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.15)' },
        enter: { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.88)' },
        leave: () => async next => {
            await delay(450)
            await next({ opacity: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)' })
        },
        trail: 0,
        unique: true
    })
    return transitions.map(({ item, key, props }) => item && <animated.div key={key} style={props} onClick={onClickOverlay} className={styles.modalOverlay} />)
}

export const ModalBodyVideo = ({ show, onEnterCallback, children }) => {
    const transitions = useTransition(show, null, {
        from: { opacity: 0 },
        enter: item => async next => {
            await delay(450)
            await next({ opacity: 1 })
            if (item) {
                onEnterCallback()
            }
        },
        leave: { opacity: 0 },
        unique: true
    })
    return transitions.map(({ item, key, props }) => item && <animated.div key={key} style={props} className={styles.modalBody}>{children}</animated.div>)
}

export default function ModalVideoContainer ({
    children,
    onEnterCallback = () => {},
    show,
    onClickOverlay
}) {
    React.useEffect(() => {
        if (show) {
            window.document.body.style.overflow = 'hidden'
        } else {
            window.document.body.style.overflow = null
        }
    }, [show])
    return ReactDOM.createPortal(
        <>
            <ModalVideoOverlay
                onClickOverlay={onClickOverlay}
                show={show}
            />
            <ModalBodyVideo show={show} onEnterCallback={onEnterCallback}>
                <span onClick={onClickOverlay} className={styles.closeIcon}>
                    <svg width={16} height={16} viewBox="0 0 16 16">
                        <title>{'CLOSE ICON'}</title>
                        <g
                            stroke="#F6F7F7"
                            strokeWidth={3}
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                        >
                            <path d="M2.35 2.35l20.3 20.3M22.65 2.35l-20.3 20.3" />
                        </g>
                    </svg>
                </span>
                {children}
            </ModalBodyVideo>
        </>,
        document.getElementById('modal-root')
    )
}
