
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsbabf3144907e67df7c6e84d888c4b56ben_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'babf3144907e67df7c6e84d888c4b56b', flattenObject(nsbabf3144907e67df7c6e84d888c4b56ben_US), true, true);
        
            import nsbabf3144907e67df7c6e84d888c4b56ben from './en.yaml'
            i18n.addResourceBundle('en', 'babf3144907e67df7c6e84d888c4b56b', flattenObject(nsbabf3144907e67df7c6e84d888c4b56ben), true, true);
        
        export default 'babf3144907e67df7c6e84d888c4b56b';
    