// @flow
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import MainLogo from 'components/MainLogo'
import cn from 'classnames'
import { formatDateTime } from 'i18n/DateFormat'

import transDomain from './translations/index.translations'
import { Store } from 'Store'

import styles from './styles.module.scss'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    campaign: { [string]: mixed },
}

PromoVoucher.defaultProps = {
    width: '541px'
}

function PromoVoucher (props: Props) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { locale, currency } = state
    const { theme, promoCode } = props
    const currencyFormatter = Currency(currency, locale)
    const { incentives, expiryDate } = state.campaign

    const value = currencyFormatter.format(incentives.rewards.value)

    return (
        <div className={styles.container}>
            <div className={styles.mainView} style={{ backgroundImage: `url(${props.theme.backgroundImage})` }}>
                <div className={styles.textContent}>
                    <h2>{t('title1')}<br />{t('title2', { type: incentives.rewards.type })}</h2>
                    <h1 style={{
                        color: theme.titleColor
                    }}>{value} on us!</h1>
                    <div>{t('valid_until')}&nbsp;<strong>{formatDateTime(expiryDate, 'long')}</strong></div>
                </div>
            </div>
            <div className={styles.separator} style={{
                backgroundColor: theme.colors.primary
            }}>
                <span className={cn(styles.message, styles.message1)}>To validate, visit</span>
                <span className={cn(styles.message, styles.message2)}>and use promo code</span>
                <a href={`${window.location.host}/${window.location.pathname.split('/')[1]}`}
                    target="_blank"
                    rel="noopener noreferrer" className={cn(styles.block, styles.block1)} style={{
                        color: theme.infoColor
                    }}>
                    <strong>{window.location.host}</strong>/{window.location.pathname.split('/')[1]}
                </a>
                <span className={cn(styles.block, styles.block2)} style={{
                    color: theme.infoColor
                }}>
                    <strong>{promoCode}</strong>
                </span>
            </div>
            <div></div>
            <div className={styles.footer}>
                <span className={styles.text} style={{
                    color: theme.textColor
                }}>
                    {t('disclaimer')}
                </span>
                <MainLogo className={styles.logoContainer} />
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(PromoVoucher)
