import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

import ALERT from './images/alert.png'

const PopupMessage = ({
    message = 'Please fill out this field',
    containerStyle,
    bottom,
    noBubble,
    shake
}) => {
    return (
        <div className={cn(styles.container, {
            [styles.bottom]: bottom,
            [styles.noBubble]: noBubble,
            [styles.shake]: shake
        })} style={containerStyle}>
            <span
                className={styles.img}
                style={{
                    backgroundImage: `url(${ALERT})`
                }}>
            </span>
            {message}
            <div className={styles.triangle}/>
        </div>
    )
}

export default PopupMessage
