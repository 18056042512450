// @flow
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import RegistrationForm from 'components/RegistrationForm'
import cn from 'classnames'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import { Currency } from 'i18n/NumberFormatter'

import type { Theme } from './themes/__supportedThemes'

import { Store } from 'Store.js'
import MainLogo from 'components/MainLogo'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme, t } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const rewards = state.campaign.incentives.rewards
    return (
        <div className={styles.mainContainer}>
            <div className={styles.bgWrapper} style={{ backgroundImage: null }}>
                <div className={styles.container}>
                    <div className={cn(styles.registerForm, styles.rightSide)}>
                        <div className={styles.formContainer}>
                            <MainLogo />
                            <span className={styles.voucherMessage} style={{ color: theme.primaryColor }} dangerouslySetInnerHTML={{
                                __html: t('title', { value: currencyFormatter.format(rewards.value), type: rewards.type })
                            }} />
                            <RegistrationForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
