// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'

import styles from './styles.module.scss'

function SummaryItem ({
    qty,
    value,
    name,
    iconUrl,
    theme
}) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    return (
        <div className={styles.container} style={{
            '--amount-color': theme.amountColor,
            '--qty-color': theme.qtyColor
        }}>
            <div className={styles.img}>
                <div className={styles.imgContainer} style={{ backgroundImage: `url(${iconUrl})` }} />
                <div className={styles.description}>{name}</div>
            </div>
            <div className={styles.info}>
                <div className={styles.amount}>{currencyFormatter.format(value)}</div>
                <div className={styles.qty}>Qty: {qty}</div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(SummaryItem)
