import * as React from 'react'

import WatchButton from 'components/WatchButton'
import ModalVideoContainer from 'components/ModalVideoContainer'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import { Store } from 'Store'

import styles from './styles.module.scss'

export const HeroVideo = ({ theme }) => {
    const { state } = React.useContext(Store)
    const [ showVideo, setVideoShow ] = React.useState(false)
    const videoPlayer = React.useRef()

    const playVideo = React.useCallback(() => {
        if (videoPlayer && videoPlayer.current) {
            videoPlayer.current.play()
        }
    }, [ videoPlayer ])

    const handleOpenModal = React.useCallback(() => {
        if (!state.campaign.videos.landing_page) return alert('Video not available')
        setVideoShow(true)
    }, [ state.campaign.videos.landing_page ])

    const handleCloseModal = React.useCallback(() => {
        setVideoShow(false)
    }, [])

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <div className={styles.container} style={{
            backgroundImage: isIE11 && `url(${theme.heroMask})`,
            '--hero-mask': `url(${theme.heroMask})`,
            '--hero-mask-tablet': `url(${theme.heroMaskTablet})`
        }}>
            <div className={styles.slide} style={{
                backgroundImage: isIE11 && `url(${theme.background})`,
                '--background-image': `url(${theme.background})`,
                '--background-image-tablet': `url(${theme.backgroundTablet})`
            }}/>
            <div className={styles.watchButtonContainer}>
                <WatchButton onClick={handleOpenModal} />
            </div>

            {state.campaign.videos.landing_page && (
                <ModalVideoContainer onEnterCallback={playVideo} show={showVideo} onClickOverlay={handleCloseModal}>
                    <video controls ref={videoPlayer}>
                        <source src={state.campaign.videos.landing_page || ''} type="video/mp4"/>
                    </video>
                </ModalVideoContainer>
            )}
        </div>
    )
}

export default withTheme(supportedThemes)(HeroVideo)
