// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { Store } from 'Store'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import Signature from './Signature'

function MessageIncentive (props) {
    const { state } = React.useContext(Store)
    const { t, theme } = props
    return (
        <div className={styles.message}>
            <p className={styles.messageParagraph}>{t('message_paragraph')}</p>

            <div className={styles.messageSignature}><Signature stroke={theme.signatureColor} /></div>

            <div className={styles.messageFooter}>
                <strong>{state.campaign.contactInfo.name}&nbsp;</strong>
                {state.campaign.contactInfo.position}, {state.campaign.client.name}
            </div>
        </div>
    )
}

export default withTheme(themes)(withTranslation(transDomain)(MessageIncentive))
