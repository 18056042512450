import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function BrandContainer ({ src, width = '100%', height = 41 }) {
    return <div className={styles.brand} style={{ backgroundImage: `url(${src})`, width, height }}></div>
}

export const Brands = ({ theme, t }) => {
    return (
        <div className={styles.container}>
            <span className={styles.info} style={{ color: theme.textColor }}>
                {t('info1')} <strong>{t('info_bold')}</strong> {t('info2')}
            </span>
            <div className={styles.brandsArea}>
                {theme.brands.map(src => (<BrandContainer key={src} src={src} />))}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Brands))
