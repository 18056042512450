// @flow
import * as React from 'react'

import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Header from 'components/Header'
import Footer from 'components/Footer'
import SummaryItem from 'components/SummaryItem'
import withTheme from 'hoc/withTheme'
import useInterval from 'hook/useInterval'
import useTimeout from 'hook/useTimeout'
import { sendAutoresponder, sendAppointmentDate, sendClientMessage } from 'Actions'
import { AppointmentWidgetWrapper } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import { Currency } from 'i18n/NumberFormatter'

import { Store } from 'Store'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

export default withTranslation(transDomain)(withTheme(supportedThemes)(({
    theme,
    t,
    location
}) => {
    const { state, dispatch } = React.useContext(Store)
    const [num, addOrRemove] = React.useState({})
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)

    const rewardsDictionary = React.useMemo(() => {
        const dictionary = {}
        state.campaign.incentives.rewards.available.forEach(reward => {
            reward.options.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                dictionary[_id] = { ...reward, sku, value }
            })
        })
        return dictionary
    }, [state.campaign.incentives.rewards.available])

    React.useEffect(() => {
        // Update/sync current rewards state using API data when mounted/submited
        if (state.lead && state.lead.incentives && state.lead.incentives.rewards) {
            const nextstate = {}
            state.lead.incentives.rewards.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                if (rewardsDictionary[_id]) {
                    nextstate[_id] = {
                        ...rewardsDictionary[_id],
                        qty: nextstate[_id] ? ++nextstate[_id].qty : 1
                    }
                }
            })
            addOrRemove(nextstate)
        }
    }, [rewardsDictionary, state.lead])

    useInterval(async () => {
        if (state.autoresponderShouldBeSent) {
            await sendAutoresponder(dispatch)
        }
    }, state.autoresponderShouldBeSent ? 1000 : null)

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidgetWrapper
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={{
                        primary: theme.colors.primary,
                        secondary: theme.colors.secondary === '#FFFFFF' ? '#000000' : theme.colors.secondary
                    }}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    if (!state.lead) {
        return <Redirect to={`${location.pathname.replace('/checkout', '')}${location.search}`} push/>
    }

    if (!state.lead || !state.lead.incentives || !state.lead.incentives.rewards) {
        return <Redirect to={`${location.pathname.replace('checkout', 'shopping')}${location.search}`} push/>
    }

    return (
        <>
            { appointmentWidget }
            <div className={styles.container}>
                <Header/>
                <div className={styles.info}>
                    <h1 style={{
                        color: theme.titleColor
                    }}>
                        {t('thanks_message', { name: state.lead.fields.firstName })}
                    </h1>
                    <p style={{ color: theme.paragraphColor }}>
                        {t('paragraph')}
                    </p>
                </div>
                <div className={styles.body} style={{
                    '--bg-color': theme.bgColor
                }}>
                    <h2 style={{
                        color: theme.titleColor
                    }}>{t('subTitle', { clientName: state.campaign.client.name })}</h2>
                    <div className={styles.itemsArea}>
                        {Object.keys(num).map(key => (
                            <SummaryItem
                                key={key}
                                qty={num[key].qty}
                                value={num[key].value}
                                name={num[key].name}
                                iconUrl={num[key].iconUrl}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}))
