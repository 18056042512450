// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store.js'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import * as Form from 'components/Form'

import Icon from './Icon'

function OrderItem (props) {
    const { theme, t, onClick, value, iconUrl, qty, setQtyOfItem, sku, name } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    return (
        <div className={styles.container}>
            <div className={styles.leftIcon}>
                <div style={{ backgroundImage: `url(${iconUrl})` }}></div>
                <span className={styles.description}>{name}</span>
            </div>
            <div className={styles.rightSide}>
                <span className={styles.price} style={{
                    color: theme.valueColor
                }}>
                    {currencyFormatter.format(value)}
                </span>
                <div
                    className={styles.formFieldContainer}
                    style={{ '--color-selected': theme.primaryColor }}>
                    <Form.Field
                        label={''}
                        type='number'
                        placeholder={t('placeholder', {
                            qty
                        })}
                        value={qty}
                        defaultValue={qty}
                        onChange={length => {
                            if (parseInt(length) >= 0) {
                                setQtyOfItem(sku, value, iconUrl, name, length)
                            }
                        }}
                    >
                    </Form.Field>
                </div>
                <span className={styles.icon}><Icon onClick={onClick} backgroundColor='red' /></span>
            </div>
        </div>
    )
}

OrderItem.defaultProps = {
    onClick: () => (console.log('alert'))
}

function areEqual (prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps)
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(React.memo(OrderItem, areEqual)))
