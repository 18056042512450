// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import Voucher from 'components/Voucher'
import Header from 'components/Header'

import { Store } from 'Store'
import useInterval from 'hook/useInterval'
import { svgToImage } from '@shift-marketing/event-websites-helper'
import { sendAutoresponder } from 'Actions'

function HeaderWithVoucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)

    const voucherRef = React.useRef(null)

    useInterval(async () => {
        if (voucherRef.current && state.autoresponderShouldBeSent) {
            await svgToImage(voucherRef.current, async blob => {
                await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
            })
        }
    }, state.autoresponderShouldBeSent ? 1000 : null)

    return (
        <>
            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>
                <Header />
            </div>

            <div className={styles.voucher}>
                <Voucher width="100%" ref={voucherRef}/>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(HeaderWithVoucher)
